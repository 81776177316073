import React from "react";
import { MdErrorOutline } from "react-icons/md";

function ErrorPage({ message }) {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-[#00133e] text-white text-center px-6">
      <MdErrorOutline className="text-red-500 h-20 w-20 animate-pulse" />
      <h1 className="text-3xl font-bold mt-4">No Data Found</h1>
      <p className="text-lg mt-2">
        {message || "No data available for this QR scanner."}
      </p>
      <button
        className="mt-6 px-6 py-2 bg-red-600 text-white rounded-lg transition duration-300 ease-in-out hover:bg-red-700"
        onClick={() => window.location.reload()}
      >
        Try Again
      </button>
    </div>
  );
}

export default ErrorPage;
