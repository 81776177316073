import { useState } from "react";
import { Link } from "react-router-dom";

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <header className="flex flex-wrap justify-between items-center py-2 px-6 bg-[#021135] shadow-md ">
      <Link
        className="text-3xl font-bold flex items-center space-x-2 text-white"
        to="/"
      >
        <img
          src="https://ownerdekhle.com/logo.png"
          alt="Owner Dekhle Logo"
          className="h-10 w-10"
        />
        <span className="text-xl">
          Owner <span className="text-green-600">Dekhle</span>
        </span>
      </Link>
      <button
        className="md:hidden text-white focus:outline-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        <svg
          className="h-6 w-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}
          />
        </svg>
      </button>
      <nav
        className={`${
          isOpen ? "block" : "hidden"
        } w-full mt-4 md:mt-0 md:w-auto md:flex`}
      >
        <ul className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-6 text-white">
          <li>
            <a
              href="#home"
              className="hover:underline focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              Home
            </a>
          </li>
          <li>
            <Link
              to="/about-us"
              className="hover:underline focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              About Us
            </Link>
          </li>
          <li>
            <a
              href="#how-it-works"
              className="hover:underline focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              How It Works
            </a>
          </li>
          <li>
            <Link
              to="/download"
              className="hover:underline focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              Download
            </Link>
          </li>
          <li>
            <Link
              to="contact-us"
              className="hover:underline focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              Contact
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
