import React from "react";
import "../Container/loadingPage.css";

function LoadingPage() {
  return (
    <div className="flex items-center justify-center h-screen bg-[#00133e]">
      <div className="text-center">
        <div className="loading-spinner mb-4">
          {/* Spinner Animation */}
          <div className="loader-circle border-4 border-t-4 border-white w-16 h-16 rounded-full animate-spin"></div>
        </div>
        <p className="text-white text-lg font-bold">
          Loading<span className="loading-dots"></span>
        </p>
      </div>
    </div>
  );
}

export default LoadingPage;
