import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { MdPhone, MdOutlineNaturePeople } from "react-icons/md";
import { GiThreeFriends } from "react-icons/gi";
import { FaPeopleLine, FaPeopleRoof } from "react-icons/fa6";
import { FiPhoneCall } from "react-icons/fi"; // Icon for emergency calls

import API from "../api";
import LoadingPage from "../Container/LoadingPage";
import ErrorPage from "../Container/ErrorPage";
import AlertBox from "../Container/AlertBox";
import { AiFillAlert } from "react-icons/ai";

function VehicleInfoPage() {
  const { QRID } = useParams();
  const [vehicleInfo, setVehicleInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState("");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    API.get(`/api/getData/vehicle-by-QR/${QRID}`)
      .then((response) => {
        if (response.data.success) {
          setVehicleInfo(response.data.vehicleInfo);
        } else {
          setError("No vehicle information found.");
        }
        setLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  }, [QRID]);

  if (loading)
    return (
      <div className="text-center text-lg">
        <LoadingPage />
      </div>
    );
  if (error)
    return (
      <div className="text-center text-lg text-red-500">
        <ErrorPage />
      </div>
    );
  return (
    <div className="bg-[#00133e] min-h-screen px-4 py-6">
      <h1 className="text-3xl font-bold text-white text-center mb-6 flex items-center justify-center gap-2">
        <span className="relative 0">
          <AiFillAlert className="h-10 w-10 animate-pulse text-red-600" />
        </span>
        <span className="text-red-600 animate-pulse">Emergency</span> O
        <span className="text-green-600">D</span>
      </h1>

      {vehicleInfo && (
        <div className="space-y-4 text-sm">
          <p>
            <span className="font-semibold text-blue-300">Owner:</span>{" "}
            <span className="font-semibold text-white">
              {vehicleInfo.ownerName}
            </span>
          </p>
          <p>
            <span className="font-semibold text-green-300">Vehicle Type:</span>{" "}
            <span className="font-semibold text-white">
              {vehicleInfo.vehicleType}
            </span>
          </p>
          <p>
            <span className="font-semibold text-purple-300">
              Vehicle Number:
            </span>{" "}
            <span className="font-semibold text-white">
              {vehicleInfo.vehicleNumber}
            </span>
          </p>
          <p>
            <span className="font-semibold text-red-300">
              Registration State:
            </span>{" "}
            <span className="font-semibold text-white">
              {vehicleInfo.registrationState}
            </span>
          </p>

          <h2 className="text-2xl font-bold text-white mb-4">
            <span className="text-red-600">Emergency</span> Contacts
          </h2>

          {/* Tabs for Owner, Family, Friends, Others */}
          <div className="grid grid-cols-2 gap-2 mt-6">
            <TabButton
              isActive={activeTab === "owner"}
              onClick={() => handleTabChange("owner")}
              icon={<MdOutlineNaturePeople className="h-8 w-8  text-red-500" />}
              label={vehicleInfo.driverName === "NA" ? "Owner" : "Driver"}
            />
            <TabButton
              isActive={activeTab === "family"}
              onClick={() => handleTabChange("family")}
              icon={<FaPeopleRoof className="h-8 w-8 text-blue-500" />}
              label="Family"
            />
            <TabButton
              isActive={activeTab === "friend"}
              onClick={() => handleTabChange("friend")}
              icon={<GiThreeFriends className="h-8 w-8 text-orange-500" />}
              label="Friend"
            />
            <TabButton
              isActive={activeTab === "others"}
              onClick={() => handleTabChange("others")}
              icon={<FaPeopleLine className="h-8 w-8 text-green-500" />}
              label="Others"
            />
          </div>

          {/* Tab Content */}
          {activeTab === "owner" && (
            <ListInfo
              member={{
                name:
                  vehicleInfo.driverName === "NA"
                    ? vehicleInfo.ownerName
                    : vehicleInfo.driverName,
                phoneNumber: vehicleInfo.customerPhoneNumber,
              }}
              index={-1}
              heading={vehicleInfo.driverName === "NA" ? "Owner" : "Driver"}
            />
          )}

          {activeTab === "family" && (
            <ContactList members={vehicleInfo.family} heading="Family" />
          )}
          {activeTab === "friend" && (
            <ContactList members={vehicleInfo.friends} heading="Friend" />
          )}
          {activeTab === "others" && (
            <ContactList members={vehicleInfo.others} heading="Others" />
          )}

          {/* Emergency Contacts */}
          <div className="mt-10">
            <div className="grid grid-cols-2 gap-2">
              <EmergencyContact
                label="Ambulance"
                phoneNumber="108"
                icon={<FiPhoneCall className="mr-2 h-8 w-8 text-red-500" />}
              />
              <EmergencyContact
                label="Police"
                phoneNumber="100"
                icon={<FiPhoneCall className="mr-2 h-8 w-8 text-blue-500" />}
              />
              <EmergencyContact
                label="Fire"
                phoneNumber="101"
                icon={<FiPhoneCall className="mr-2 h-8 w-8 text-orange-500" />}
              />
              <EmergencyContact
                label="Women Help"
                phoneNumber="181"
                icon={<FiPhoneCall className="mr-2 h-8 w-8 text-green-500" />}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const TabButton = ({ isActive, onClick, icon, label, index }) => {
  let color = "white";
  if (index === 0) {
  }
  return (
    <button
      className={`${
        isActive ? "bg-gray-800 text-white " : "bg-[#004d81] text-white"
      } px-3 py-3 rounded-lg grid grid-cols-3 items-center text-sm transition duration-300 ease-in-out transform hover:scale-105`}
      onClick={onClick}
    >
      <div>{icon}</div>

      <div>{label}</div>
    </button>
  );
};

const ContactList = ({ members, heading }) => (
  <ul className="space-y-4">
    {members.map((member, index) => (
      <ListInfo member={member} index={index} heading={heading} key={index} />
    ))}
  </ul>
);

const ListInfo = ({ member, index, heading }) => {
  return (
    <li className="mb-4">
      <p className="flex items-center text-white text-base">
        <span className="font-semibold mr-2">
          {heading} {index > -1 && <span>{index + 1}</span>} -{" "}
        </span>
        <span className="italic">{member.name}</span>
      </p>
      <div className="flex flex-col space-y-2 mt-2">
        <a
          href={`tel:${member.phoneNumber}`}
          className="text-blue-500 hover:text-blue-700 inline-flex items-center bg-white w-full p-2 rounded-lg text-sm"
        >
          <MdPhone className="mr-2 h-5 w-5" />
          Call Now
        </a>
        {/* <a
          href={`https://wa.me/+91${member.phoneNumber}?text=Hello, I'm contacting you regarding your vehicle.`}
          className="text-green-500 hover:text-green-700 inline-flex items-center bg-white w-full p-2 rounded-lg text-sm"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://pluspng.com/img-png/whatsapp-png-whatsapp-transparent-png-image-1012.png"
            alt="WhatsApp"
            className="mr-2 w-5 h-5"
          />
          Send WhatsApp
        </a> */}
      </div>
    </li>
  );
};

const EmergencyContact = ({ label, phoneNumber, icon }) => {
  return (
    <a
      href={`tel:${phoneNumber}`}
      className="text-white grid grid-cols-3 hover:bg-gray-800 bg-[#004d81] p-3 rounded-lg  items-center justify-center text-base transition duration-300 ease-in-out transform hover:scale-105"
    >
      {icon}
      <span>{label}</span>
    </a>
  );
};

export default VehicleInfoPage;
