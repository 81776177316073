import React, { useState, useEffect } from "react";
import { MdPhone } from "react-icons/md";
import { FiPhoneCall } from "react-icons/fi"; // Icon for emergency calls
import LoadingPage from "../Container/LoadingPage";
import ErrorPage from "../Container/ErrorPage";
import { AiFillAlert } from "react-icons/ai";
import API from "../api";
import { useParams } from "react-router-dom";

function KidsLocketInfoPage() {
  const { QRID } = useParams();
  console.log(QRID);

  const [kidInfo, setKidInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // For demonstration, we are using dummy data. Replace with actual API call when available.
  useEffect(() => {
    API.get(`/api/getData/kids-locket-data-by-QR/${QRID}`)
      .then((response) => {
        if (response.data.success) {
          console.log(response.data);
          setKidInfo(response.data.kidsInfo);
        } else {
          setError("No information found.");
        }
        setLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  }, [QRID]);

  if (loading)
    return (
      <div className="text-center text-lg">
        <LoadingPage />
      </div>
    );
  if (error)
    return (
      <div className="text-center text-lg text-red-500">
        <ErrorPage />
      </div>
    );

  return (
    <div className="bg-[#00133e] min-h-screen px-4 py-6">
      <h1 className="text-3xl font-bold text-white text-center mb-6 flex items-center justify-center gap-2">
        <span className="relative 0">
          <AiFillAlert className="h-10 w-10 animate-pulse text-red-600" />
        </span>
        <span className="text-red-600 animate-pulse">Emergency</span> O
        <span className="text-green-600">D</span>
      </h1>
      <h2 className="text-2xl font-bold text-white text-center mb-6 flex items-center justify-center gap-2">
        Child Locket Information
      </h2>

      {kidInfo && (
        <div className="space-y-4 text-sm">
          <p>
            <span className="font-semibold text-blue-300">Child's Name:</span>{" "}
            <span className="font-semibold text-white">{kidInfo.kidName}</span>
          </p>
          <p>
            <span className="font-semibold text-green-300">Age:</span>{" "}
            <span className="font-semibold text-white">{kidInfo.kidAge}</span>
          </p>
          <p>
            <span className="font-semibold text-purple-300">School:</span>{" "}
            <span className="font-semibold text-white">
              {kidInfo.schoolName}
            </span>
          </p>
          <p>
            <span className="font-semibold text-yellow-300">Class:</span>{" "}
            <span className="font-semibold text-white">
              {kidInfo.classNSection}
            </span>
          </p>
          <p>
            <span className="font-semibold text-red-300">Blood Group:</span>{" "}
            <span className="font-semibold text-white">
              {kidInfo.bloodGroup}
            </span>
          </p>

          {/* Medical and Allergy Information */}
          {kidInfo.medicalConditions.length > 0 && (
            <p>
              <span className="font-semibold text-red-400">
                Medical Conditions:
              </span>{" "}
              <span className="font-semibold text-white">
                {kidInfo.medicalConditions.length
                  ? kidInfo.medicalConditions.join(", ")
                  : "None"}
              </span>
            </p>
          )}
          {kidInfo.allergies.length > 0 && (
            <p>
              <span className="font-semibold text-red-400">Allergies:</span>{" "}
              <span className="font-semibold text-white">
                {kidInfo.allergies.join(", ")}
              </span>
            </p>
          )}

          {/* Parent's Contact */}
          <h2 className="text-2xl font-bold text-white mb-4">
            Parents' Contact
          </h2>
          <PhoneLink
            label={"Father :" + kidInfo.fatherName}
            phoneNumber={kidInfo.fatherNumber}
          />
          <PhoneLink
            label={"Mother :" + kidInfo.motherName}
            phoneNumber={kidInfo.motherNumber}
          />

          {/* Other Contact */}
          {kidInfo.otherName && kidInfo.otherNumber && (
            <>
              <h2 className="text-2xl font-bold text-white mb-4">
                Other Contact
              </h2>
              <PhoneLink
                label={kidInfo.otherName}
                phoneNumber={kidInfo.otherNumber}
              />
            </>
          )}

          {/* Address */}
          {kidInfo.address && (
            <p>
              <span className="font-semibold text-blue-300">Address:</span>{" "}
              <span className="font-semibold text-white">
                {kidInfo.address}
              </span>
            </p>
          )}

          {/* Emergency Contacts Section */}
          <h2 className="text-2xl font-bold text-white mb-4">
            <span className="text-red-600">Emergency</span> Contacts
          </h2>
          <div className="grid grid-cols-2 gap-2 mt-6">
            <EmergencyContact
              label="Ambulance"
              phoneNumber="108"
              icon={<FiPhoneCall className="mr-2 h-8 w-8 text-red-500" />}
            />
            <EmergencyContact
              label="Police"
              phoneNumber="100"
              icon={<FiPhoneCall className="mr-2 h-8 w-8 text-blue-500" />}
            />
            <EmergencyContact
              label="Fire"
              phoneNumber="101"
              icon={<FiPhoneCall className="mr-2 h-8 w-8 text-orange-500" />}
            />
            <EmergencyContact
              label="Women Help"
              phoneNumber="181"
              icon={<FiPhoneCall className="mr-2 h-8 w-8 text-green-500" />}
            />
          </div>
        </div>
      )}
    </div>
  );
}

const PhoneLink = ({ label, phoneNumber }) => {
  if (!phoneNumber) return null; // Do not render anything if phoneNumber is not available
  return (
    <p>
      <span className="font-semibold text-green-300">{label}</span>
      <a
        href={`tel:${phoneNumber}`}
        className="text-blue-500 hover:text-blue-700 inline-flex items-center bg-white w-full p-2 rounded-lg text-sm"
      >
        <MdPhone className="mr-2 h-5 w-5" />
        Call Now
      </a>
    </p>
  );
};

const EmergencyContact = ({ label, phoneNumber, icon }) => {
  return (
    <a
      href={`tel:${phoneNumber}`}
      className="text-white grid grid-cols-3 hover:bg-gray-800 bg-[#004d81] p-3 rounded-lg items-center justify-center text-base transition duration-300 ease-in-out transform hover:scale-105"
    >
      {icon}
      <span>{label}</span>
    </a>
  );
};

export default KidsLocketInfoPage;
